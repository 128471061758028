<template>
  <div class="right">
    <span class="spen">账号绑定</span>
    <div class="daohang">
      <ul class="ul">
        <router-link :to="{ name: 'User' }" tag="li">个人基本信息</router-link>
        <router-link :to="{ name: 'Safe' }" tag="li">账户安全</router-link>
        <router-link :to="{ name: 'Bind' }" tag="li">账号绑定</router-link>
        <router-link :to="{ name: 'Address' }" tag="li">收货地址</router-link>
      </ul>
    </div>
    <div class="list1">
      <div class="list1-left">
        <div class="list1-left-T">
          <img src="../../../assets/order/weixin.png" alt="" />
          <div class="bangding">绑定微信账号</div>
          <div class="weibd">
            <div v-if="istrue == 'false'">未绑定</div>
            <div class="bang1" v-if="istrue == 'true'">已绑定</div>
            <div class="deletebind" v-if="istrue == 'true'" @click="deletebindwx">解绑</div>
          </div>
        </div>
        <div class="list1-left-C">绑定后，可以使用微信登录标物商城购物</div>
        <!-- <div class="list1-left-B">去赚积分</div> -->
      </div>
      <div class="list1-right">
        <!-- <div class="list1-right-t">绑定</div> -->
        <div class="list1-right-b">
          <img class="img1" src="../../../assets/order/bw.png" alt="" />
          <img class="img2" src="../../../assets/order/zh.png" alt="" />
          <img class="img3" src="../../../assets/order/tx.png" alt="" />
        </div>
      </div>
    </div>
    <!-- <div class="list">
      <div class="list-left">
        <img src="../../../assets/order/weixin.png" alt="" />
        <div class="bang">绑定微信账号</div>
        <div class="bang1">已绑定</div>
      </div>
      <div class="list-right">解绑</div>
    </div>
    <div class="list">
      <div class="list-left">
        <img src="../../../assets/order/zhifubao.png" alt="" />
        <div class="bang">绑定支付宝账号</div>
        <div class="bang1">已绑定</div>
      </div>
      <div class="list-right">解绑</div>
    </div> -->
  </div>
</template>
<script>
import { getCookie } from '@/utils/cookie';
import { get } from '@/utils/request';
export default {
  data() {
    return {
      istrue: 'false',
    };
  },
  created() {
    if (getCookie('memberId')) {
      this.getisBindwx(getCookie('memberId'));
    }
  },
  methods: {
    getisBindwx(e) {
      get('api/account/getIsBindWx?memberId=' + e + '').then((res) => {
        this.istrue = res.data.msg;
      });
    },
    deletebindwx() {
      this.$confirm('是否确认解绑?', this.$t('common.Confirmation'), {
        confirmButtonText: this.$t('button.Confirm'),
        cancelButtonText: this.$t('button.Cancel'),
        type: 'warning',
      }).then(() => {
        get(
          'api/account/relieveWxBind?memberId=' + getCookie('memberId') + ''
        ).then((res) => {
          this.$message.success({
            message: '解绑成功',
          });
          this.$router.go(0)
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.right {
  width: 1006px;
  padding-top: 34px;
  padding-left: 27px;
  background: #fff;
  .spen {
    font-size: 26px;
    font-weight: 600;
    color: #616161;
  }

  .daohang {
    font-size: 15px;
    color: #272727;
    margin-right: 25px;
    height: 60px;

    .ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0;
      width: 400px;
      li {
        list-style: none;
        cursor: pointer;
        &.router-link-exact-active.router-link-active {
          color: #3661fe;
        }
      }
    }
  }
  .list1 {
    margin-top: 20px;
    width: 900px;
    height: 166px;
    background: #f0f5f9;
    padding: 0 43px 0 33px;
    display: flex;
    &-left {
      width: 487px;
      height: 125px;
      border-right: 2px solid #e5e5e5;
      padding-top: 17px;
      padding-bottom: 24px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      &-T {
        padding-right: 10px;
        display: flex;
        align-items: center;
        .bangding {
          margin-left: 8px;
          color: #333;
          font-size: 15px;
        }
        .weibd {
          flex: 1;
          font-size: 16px;
          color: #1951ff;
          margin-left: 12px;
          display: flex;
          justify-content: space-between;
          .bang1 {
            color: #de8740;
          }
          .deletebind{
            cursor: pointer;
          }
        }
      }
      &-C {
        font-size: 22px;
        color: #1951ff;
      }
      &-B {
        width: 94px;
        height: 21px;
        border-radius: 5px;
        border: 2px solid #1850ff;
        font-size: 14px;
        color: #1850ff;
        line-height: 21px;
        text-align: center;
      }
    }
    &-right {
      flex: 1;
      height: 125px;
      padding: 37px 0 24px 40px;
      &-t {
        font-size: 16px;
        color: #1951ff;
        text-align: right;
      }
      &-b {
        margin-top: 26px;
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .list {
    margin-top: 20px;
    width: 900px;
    height: 65px;
    background: #f0f5f9;
    padding: 0 43px 0 33px;
    display: flex;
    justify-content: space-between;
    &-left {
      display: flex;
      align-items: center;
      font-size: 16px;
      .bang {
        margin-left: 10px;
        color: #333;
      }
      .bang1 {
        margin-left: 15px;
        color: #de8740;
      }
    }
    &-right {
      color: #1850ff;
      font-size: 16px;
      line-height: 65px;
    }
  }
}
</style>
